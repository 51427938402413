<template>
  <div class="human_page">
    <!-- 头部 -->
      <div class="banner">
        <common-header style="position:absolute" :navActive="3" />
      </div>
      <div class="icon_show">
        <el-breadcrumb style="font-size: 16px;" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/human' }">人力资源</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="human_fourCase" ref="humanFourCase">
        <div class="human_fourCase_module">
          <div class="human_fourCase_module_title" ref="humanFourCaseModuleTitle">直击企业4大痛点</div>
          <div class="human_fourCase_module_trouble" ref="humanFourCaseModuleTrouble">
            <div class="human_fourCase_module_trouble_box"  v-for="(item,index) in troubleBox" :key="index">
              <div class="human_fourCase_module_trouble_box_img">
                <img :src="item.src" >
              </div>
              <div class="human_fourCase_module_trouble_box_info">
                <div class="human_fourCase_module_trouble_box_info_title">{{item.title}}</div>
                <span>{{item.span1}}</span>
                <span>{{item.span2}}</span>
                <span>{{item.span3}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="service" ref="service">
        <div class="service_title" ref="serviceTitle">我们的服务特色</div>
        <div ref='serviceBanner' class="server_box">
          <div class="service_banner"  v-for="(item,index) in serviceInfo" :key="index">
            <div class="service_banner_item">
              <div class="service_banner_left">
                <div class="service_banner_left_box">
                  <div class="service_banner_left_box_number">{{item.number}}</div>
                  <div class="service_banner_left_box_title">
                    <img :src="item.icon" />
                    <div style="display: inline-block;padding-left: 10px;">
                      <div class="service_banner_left_box_title_text">{{item.name}}</div>
                      <span>{{item.info}}</span>
                    </div>
                  </div>
                  <div class="service_banner_left_box_mean">
                    <div v-for="(item,index) in item.meanText" :key="index" style="margin-top: 46px">
                      <span></span><p>{{item.text}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="service_banner_right">
                <img :src="item.src"/>
              </div>
            </div>
          </div>
          <div class="service_banner_tab">
            <div class="service_banner_tab_shadow">
              <div :class="isVal === index ? 'service_banner_tab_box box_ts' : 'service_banner_tab_box'" v-for="(item,index) in tabBox" :key="index" @mouseover="hoverModule(index)">
                <div class="service_banner_tab_box_img">
                  <img :src="isVal === index ? item.hoverSrc : item.src"/>
                </div>
                <div class="service_banner_tab_box_text">
                  <p class="service_banner_tab_box_text_p">{{item.title}}</p>
                  <span class="service_banner_tab_box_text_span">{{item.text}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kind" ref="kind">
        <div class="kind_module">
          <div  class="kind_module_title" ref="kindModuleTitle">服务工种</div>
          <div class="kind_module_box" ref="kindModuleBox">
            <div class="kind_module_box_info" v-for="(item,index) in boxInfo" :key="index">
              <div class="kind_module_box_info_box">
                <div class="kind_module_box_info_img">
                  <img :src="item.src"/>
                </div>
              </div>
              <div class="kind_module_box_info_text">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    <!-- 路由出口 -->
    <!-- 底部 -->
    <common-footer />
  </div>
</template>
<script>
// 头部公共导航栏
import CommonHeader from '@/components/head'
// 底部公共导航栏
import CommonFooter from '@/components/footer/foot_Index.vue'
export default {
  name: 'humanIndex',
  components: {
    CommonHeader,
    CommonFooter
  },
  data () {
    return {
      isVal: 0,
      index: null,
      troubleBox: [
        { src: require('@/assets/img/human/icon1.png'), title: '基础岗位问题', span1: '人员需求量大', span2: '流动频繁', span3: '人员管理难度大' },
        { src: require('@/assets/img/human/icon2.png'), title: '临时性用工需求', span1: '短期项目急需用人', span2: '招聘力量不能满足项目', span3: '工期需求' },
        { src: require('@/assets/img/human/icon3.png'), title: '短期人员短缺', span1: '正式编制员工业务繁忙', span2: '病假、产假等造成项目', span3: '无人承接' },
        { src: require('@/assets/img/human/icon4.png'), title: '风险与成本', span1: '用工风险、人力成本', span2: '对企业造成的', span3: '双重压力' }
      ],
      boxInfo: [
        { src: require('@/assets/img/human/type1.png'), title: '项目经理' },
        { src: require('@/assets/img/human/type2.png'), title: '产品经理' },
        { src: require('@/assets/img/human/type3.png'), title: 'JAVA工程师' },
        { src: require('@/assets/img/human/type4.png'), title: 'WEB前端工程师' },
        { src: require('@/assets/img/human/type5.png'), title: 'UI设计师' },
        { src: require('@/assets/img/human/type6.png'), title: '测试工程师' }
      ],
      serviceInfo: [
        {
          number: '01',
          src: require('@/assets/img/human/bg1.jpg'),
          icon: require('@/assets/img/human/service1.png'),
          name: '专业团队',
          info: 'PROFESSIONAL  TEAM',
          meanText: [
            { text: '企业专职顾问，针对企业需求快速响应' },
            { text: '顾问实操经验丰富，全力满足客户临时性、突发性需求' },
            { text: '人员稀缺时快速提供应急方案，降低企业风险' }
          ]
        },
        {
          number: '02',
          src: require('@/assets/img/human/bg2.jpg'),
          icon: require('@/assets/img/human/service2.png'),
          name: '人才充足',
          info: 'TALENT IS ENOUGH',
          meanText: [
            { text: '产品经理全程跟进把控，严格保障系统质量与周期' },
            { text: '多年软件开发行业经验，专业技术开发团队' },
            { text: '成熟的人才招聘渠道及专业的软件人才库，专业技能人才储备充足' }
          ]
        },
        {
          number: '03',
          src: require('@/assets/img/human/bg3.jpg'),
          icon: require('@/assets/img/human/service3.png'),
          name: '定制服务',
          info: 'CUSTOMIZED SERVICE',
          meanText: [
            { text: '为企业提供高品质互联网营销整合服务' },
            { text: '以用户的营销需求为出发点' },
            { text: '个性化定制，打造您的专属代码' }
          ]
        },
        {
          number: '04',
          src: require('@/assets/img/human/bg4.jpg'),
          icon: require('@/assets/img/human/service4.png'),
          name: '质量保障',
          info: 'QUALITY ASSURANCE',
          meanText: [
            { text: '系统稳定流畅，后续升级迭代投入低，时间快' },
            { text: '一切品牌策略和创意以落地为最终目标，用心服务，让您的项目快速启动，完美落地' },
            { text: '创意设计兼顾实用与执行，最大程度发挥创意对市场营销的推动' }
          ]
        }
      ],
      tabBox: [
        { src: require('@/assets/img/human/service1.png'), hoverSrc: require('@/assets/img/human/hover1.png'), title: '专业团队', text: 'PROFESSIONAL  TEAM' },
        { src: require('@/assets/img/human/service2.png'), hoverSrc: require('@/assets/img/human/hover2.png'), title: '人才充足', text: 'SUFFICIENT TALENTS' },
        { src: require('@/assets/img/human/service3.png'), hoverSrc: require('@/assets/img/human/hover3.png'), title: '定制服务', text: 'CUSTOMIZED SERVICES' },
        { src: require('@/assets/img/human/service4.png'), hoverSrc: require('@/assets/img/human/hover4.png'), title: '质量保障', text: 'QUALITY ASSURANCE' }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    const mouseDomArr = document.querySelectorAll('.kind_module_box_info_box')
    mouseDomArr.forEach(item => {
      item.addEventListener('mouseover', () => {
        item.className += ' tss'
      })
      item.addEventListener('mouseout', () => {
        item.className = 'kind_module_box_info_box'
      })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    hoverModule (val) {
      this.isVal = val
      const list = document.querySelectorAll('.service_banner')
      list.forEach((item, index) => {
        if (val === index) {
          item.style = 'display: block'
        } else {
          item.style = 'display: none'
        }
      })
    },
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.humanFourCase.offsetTop - 1550) {
        if (this.$refs.humanFourCaseModuleTitle.className === 'human_fourCase_module_title') {
          this.$refs.humanFourCaseModuleTitle.className = this.$refs.humanFourCaseModuleTitle.className + ' animated fadeInUpBig'
          this.$refs.humanFourCaseModuleTrouble.className = this.$refs.humanFourCaseModuleTrouble.className + ' animated fadeInDownBig'
        }
      }
      if (__currentScroll >= this.$refs.service.offsetTop - 750) {
        if (this.$refs.serviceTitle.className === 'service_title') {
          this.$refs.serviceTitle.className = this.$refs.serviceTitle.className + ' animated fadeInUpBig'
          this.$refs.serviceBanner.className = this.$refs.serviceBanner.className + ' animated fadeInDownBig'
        }
      }
      if (__currentScroll >= this.$refs.kind.offsetTop - 900) {
        if (this.$refs.kindModuleTitle.className === 'kind_module_title') {
          this.$refs.kindModuleTitle.className = this.$refs.kindModuleTitle.className + ' animated fadeInUpBig'
          this.$refs.kindModuleBox.className = this.$refs.kindModuleBox.className + ' animated fadeInDownBig'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tss {
  transform:rotateY(360deg);
  transition: all 1s;
}
.human_page{
  overflow: hidden;
  width: 100%;
  min-width: 1400px;
  .banner {
      height: 500px;
      background: url('~@/assets/img/human/inner-banner.jpg') no-repeat center;
      background-size: 100% 100%;
  }
  .icon_show {
    width: 1400px;
    padding-top: 39px;
    margin: auto;
    .icon_title {
      width: 113px;
      height: 16px;
      font-size: 16px;
      letter-spacing: 0px;
      color: #333333;
    }
  }
  .human_fourCase{
    width: 1400px;
    margin: auto;
    padding-top: 39px;
    overflow: hidden;
    .human_fourCase_module{
      height: 355px;
      .human_fourCase_module_title{
        letter-spacing: 4px;
        color: #333333;
        font-family: MicrosoftYaHei;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
      }
      .human_fourCase_module_trouble{
        display: flex;
        justify-content: space-between;
        .human_fourCase_module_trouble_box{
          width: 335px;
          height: 260px;
          border-radius: 6px;
          border: solid 2px #f5f5f5;
          margin-top: 57px;
          .human_fourCase_module_trouble_box_img{
            display: flex;
            justify-content: center;
            margin-top: 21px;
            transition:all .6s;
            img{
              width: 59px;
              height: 59px;
            }
          }
          .human_fourCase_module_trouble_box_info{
            text-align: center;
            margin-top: 40px;
            .human_fourCase_module_trouble_box_info_title{
              font-family: MicrosoftYaHei;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 2px;
              color: #333333;
              margin-bottom: 19px;
            }
            span{
              font-family: MicrosoftYaHei;
              font-size: 14px;
              line-height: 24px;
              color: #333333;
              display: block;
            }
          }
        }
      }
      .human_fourCase_module_trouble_box:hover .human_fourCase_module_trouble_box_img{
        transform:scale(1.3)
      }
    }
  }
  .service{
    width: 100%;
    height: 1000px;
    background-color: #003982;
    margin-top: 60px;
    overflow: hidden;
    .server_box{
      width: 1400px;
      height: 700px;
      margin: auto;
      overflow: hidden;
      background-color: #ffffff;
      position: relative;
      .service_banner{
        width: 100%;
        height: 100%;
        display: flex;
        display: none;
      }
      .service_banner:nth-child(1) {
        display: block;
      }
      .service_banner_item{
        width: 100%;
        height: 100%;
        display: flex;
      }
      .service_banner_left{
        height: 100%;
        width: 50%;
        .service_banner_left_box{
          width: 620px;
          margin: 0 auto;
          .service_banner_left_box_number{
            height: 94px;
            font-size: 90px;
            font-style: italic;
            letter-spacing: 9px;
            color: #ebebeb;
            display: flex;
            justify-content: flex-end;
          }
          .service_banner_left_box_title{
            height: 75px;
            border-bottom: 1px solid #ccc;
            .service_banner_left_box_title_text{
              font-size: 28px;
              letter-spacing: 3px;
            }
            span{
            font-family: MicrosoftYaHei;
            font-size: 14px;
            letter-spacing: 1px;
            color: #666666;
            }
          }
          .service_banner_left_box_mean{
            height: 459px;
            span{
              width: 12px;
              height: 12px;
              background-color: #0773fc;
              display: inline-block;
              border-radius: 6px;
            }
            p{
              display: inline-block;
              font-size: 14px;
              letter-spacing: 1px;
              margin-left: 10px;
            }
          }
        }
      }
      .service_banner_right{
        height: 100%;
        width: 50%;
      }
      .service_banner_tab{
        width: 100%;
        height: 200px;
        position: absolute;
        margin: auto;
        display: flex;
        left: 0;
        justify-content: center;
        top: 428px;
        .service_banner_tab_shadow{
          box-shadow: 0px 0px 15px #ccc;
          display: flex;
          background-color: #fff;
        }
        .service_banner_tab_box{
          width: 200px;
          height: 200px;
          .service_banner_tab_box_img{
            display: flex;
            justify-content: center;
            padding-top: 33px;
          }
          .service_banner_tab_box_text{
            text-align: center;
            padding-top: 29px;
            .service_banner_tab_box_text_p{
              font-family: MicrosoftYaHei;
              font-size: 28px;
              letter-spacing: 3px;
            }
            .service_banner_tab_box_text_span{
              font-size: 14px;
              letter-spacing: 1px;
              color: #666666;
            }
          }
        }
      }
    }
    .service_title{
      font-family: MicrosoftYaHei;
      font-size: 36px;
      letter-spacing: 4px;
      color: #ffffff;
      line-height: 36px;
      padding-top: 59px;
      text-align: center;
      margin-bottom: 59px;
    }
  .box_ts {
    background-color: #3179ff;
    .service_banner_tab_box_text_p {
      color: #fff;
    }
    .service_banner_tab_box_text_span{
      color: #fff!important
    }
  }
  }
  .kind{
    width: 1400px;
    margin: auto;
    padding-top: 59px;
    overflow: hidden;
    .kind_module{
      .kind_module_title{
        font-family: MicrosoftYaHei;
        font-size: 36px;
        letter-spacing: 4px;
        color: #333333;
        text-align: center;
      }
      .kind_module_box{
        margin-top: 60px;
        display: flex;
        .kind_module_box_info{
          width: 260px;
          .kind_module_box_info_img{
            display: flex;
            justify-content: center;
            img{
              width: 160px;
              height: 160px;
            }
          }
          .kind_module_box_info_text{
            text-align: center;
            font-family: MicrosoftYaHei;
            font-size: 24px;
            letter-spacing: 2px;
            color: #333333;
            padding-top: 52px;
          }
        }
      }
    }
  }
}
@keyframes iconbacktop{
  0% {top: 385px;}
  20% {top: 308px;}
  40% {top: 231px;}
  60% {top: 154px;}
  80% {top: 77px}
  100% {top: 0}
}
</style>
