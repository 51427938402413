<template>
  <div class="software">
    <!-- 头部 -->
    <common-header style="position:absolute" :navActive="5" />
    <!-- 路由出口 -->
    <router-view />
    <!-- 底部 -->
    <common-footer />
  </div>
</template>
<script>
// 头部公共导航栏
import CommonHeader from '@/components/head'
// 底部公共导航栏
import CommonFooter from '@/components/footer/foot_Index.vue'
export default {
  name: 'softIdex',
  components: {
    CommonHeader,
    CommonFooter
  }
}
</script>
<style lang="less" scoped>
.software {
  width: 100%;
  min-width: 1400px;
}
</style>
